import { AdjustmentsIcon, BeakerIcon, PhoneIcon, CogIcon, UserIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

import useScript from "../hooks/useScript"
import { useState, useRef, useEffect } from "react"

// import "../SCSS/Components/EmptyHeroRectangle.scss"
// import '../SCSS/Components/PiscesDemo.scss'


const features = [
  {
    name: 'Massively Customizable',
    description:
      "Pisces is built from the ground up to be customizable. Give it a curated dataset, and it's ready to learn quickly and exceptionally well.",
    icon: AdjustmentsIcon,
  },
  {
    name: 'State-of-the-Art Tooling',
    description:
      "Pisces is built on top of the latest and greatest deep learning models. It's designed to use PyTorch and Hydra for performant deep learning, GPU capabilities, and customization.",
    icon: BeakerIcon,
  },
  {
    name: 'Extensible',
    description:
      'Pisces can be readily extended to a number of downstream tasks: speech-to-text, speaker recognition, speaker verification, and so many more. Pisces lays the groundwork for a suite of applications.',
    icon: CogIcon,
  },
  {
    name: 'Designed for Phone Calls',
    description:
      'A strength of Pisces is that it works well with low quality audio, low sampling rates, and low SNRs. Pisces uses realistic data to get you great results.',
    icon: PhoneIcon,
  },
]

export default function Pisces() {
	useScript('/scripts/animated-background.js')

	const [selectedValue, setSelectedValue] = useState('20')
	const inputCallAudioRef = useRef()
	const agentCallAudioRef = useRef()
	const callerAudioRef = useRef()

	const demonstrationValueDidChange = event => {
		setSelectedValue(event.target.value)
	}

	useEffect(() => {
		let newSource = ''
		if (selectedValue === '20') {
			newSource = 'audio/SNR_20db/'
		} 
		else if (selectedValue === '0') {
			newSource = 'audio/SNR_0db/'
		} 
		else if (selectedValue === '-5') {
			newSource = 'audio/SNR_-5db/'
		}
		else if (selectedValue === 'callhome') {
			newSource = 'audio/callhome/'
		}
		
		if (inputCallAudioRef.current) {
			inputCallAudioRef.current.src = newSource + 'input.wav'
			inputCallAudioRef.current.load()
		}
		if (agentCallAudioRef.current) {
			agentCallAudioRef.current.src = newSource + 'agent.wav'
			agentCallAudioRef.current.load()
		}
		if (callerAudioRef.current) {
			callerAudioRef.current.src = newSource + 'caller.wav'
			callerAudioRef.current.load()
		}
	}, [selectedValue])

	const InputParagraph = () => {
		if (selectedValue === 'callhome') {
			return (
				<div>
					<p className="mt-1">
						This sample is from the CALLHOME corpus, which consists of real calls between acquaintances. Pisces has not been developed on real phone call data. Yet, the system is able to handle this type of data, due to the training process we developed.
					</p>
					<p className="mt-1">
						With the training process we developed, coupled with fine-tuning and adaptation, Pisces shows promise of being able to handle a wide variety of unseen test conditions with a robust, production-ready performance.
					</p>
				</div>
			)
		}
		else {
			return (
				<p className="mt-1">
					This sample simulates a call that we want to split into separate speakers. We add white noise to the background of the call at an SNR of {selectedValue}dB.
				</p>
			)
		}
	}

	return (
		<div className="max-w-7xl mx-auto sm:px-0 lg:px-8">
			<div className="relative empty-hero-rectangle">
				<div className="empty-hero-rectangle__title-box relative z-50">
					<h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl empty-hero-rectangle__title text-warm-gray-900">
						Pisces
					</h1>
					<h2 className="text text-2xl font-light tracking-tight empty-hero-rectangle__title text-warm-gray-900">
						innovative call splitting
					</h2>
				</div>
				<canvas id="canvas" className="animated-background-container" width="32px" height="32px" />
			</div>
			<div className="pt-24 bg-white">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="lg:text-center">
						<h2 className="text-base text-cyan-600 font-semibold tracking-wide uppercase">Deep Learning + Signal Processing</h2>
						<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">
							Call Separation
						</p>
						<p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
							Pisces splits a single-channel recording of multiple speakers into individual tracks, one for each speaker in the recording. It's built on top of state-of-the-art deep learning technology.
						</p>
					</div>

					<div className="mt-12">
						<dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
							{features.map((feature) => (
							<div key={feature.name} className="relative">
								<dt>
									<div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
										<feature.icon className="h-6 w-6" aria-hidden="true" />
									</div>
									<p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
								</dt>
								<dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
							</div>
							))}
						</dl>
					</div>

					<div className="bg-cyan-600 mt-24">
						<div className="max-w-7xl mx-auto py-12 px-8 sm:py-16 sm:px-8 lg:px-8 lg:py-20">
							<div className="max-w-4xl mx-auto lg:text-center">
								<h2 className="text-3xl font-extrabold text-white sm:text-5xl">Leverage Your Speech Data</h2>
								<p className="mt-3 text-xl text-cyan-200 sm:mt-4">
									To gain actionable insight from your calls, you need great technology that splits your calls reliably. Pisces does just that. It performs well, measured by commonly used metrics in speech-separation research.
								</p>
							</div>
							<dl className="my-12 sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-2 sm:gap-8 text-center">
								<div className="flex flex-col mt-10 sm:mt-0">
									<dt className="order-2 mt-2 text-lg leading-6 font-medium text-cyan-200">PESQ*</dt>
									<dd className="order-1 text-5xl font-extrabold text-white">4.51 / 5.0</dd>
								</div>
								<div className="flex flex-col mt-10 sm:mt-0">
									<dt className="order-2 mt-2 text-lg leading-6 font-medium text-cyan-200">STOI*</dt>
									<dd className="order-1 text-5xl font-extrabold text-white">0.99 / 1.0</dd>
								</div>
							</dl>
							<p className="mt-12 text-sm italic text-cyan-200 sm:mt-4 lg:text-center">
								*These are measured on separated audio output by our current system, relative to ground truth, and averaged over hundreds of samples from a held-out simulated test set.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
				<div className="lg:flex lg:justify-between">
					<div className="max-w-xl">
						<h2 className="text-3xl font-extrabold text-gray-900 sm:tracking-tight sm:text-5xl">
							See it in Action
						</h2>
						<p className="mt-5 text-xl text-gray-500">
							We've provided demonstration files with a variety of testing conditions. We have the easy case, clean audio. In addition, we test against two low SNRs. We provide a sample from the CALLHOME corpus, as well.
						</p>
						<p className="mt-5 text-xl text-gray-500">
							The provided files are real outputs of our current system, which is still in active development. Please select the test case you're interested in from the adjacent menu.
						</p>
						<p className="mt-5 text-sm italic text-gray-500">
							The testing data is simulated phone calls, with speakers taken from the popular Librispeech corpus. The input files have not been seen by the system during development. 
						</p>
					</div>
					<div className="mt-10 w-full max-w-xs">
						<label htmlFor="demonstration" className="text-base text-cyan-600 font-semibold tracking-wide">
							Test Condition
						</label>
						<div className="mt-1.5 relative">
							<select
								id="demonstration"
								name="demonstration"
								className="appearance-none block w-full bg-none bg-white border border-gray-300 rounded-md pl-3 pr-10 py-2 text-base text-gray-900 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
								value={selectedValue}
								onChange={demonstrationValueDidChange}
							>
								<option value='20'>SNR: 20dB</option>
								<option value='0'>SNR: 0dB</option>
								<option value='-5'>SNR: -5dB</option>
								<option value='callhome'>CALLHOME Corpus</option>
							</select>
							<div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
								<ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
							</div>
						</div>
					</div>
				</div>
				<div className="flex mt-10 w-full grid grid-cols-1 gap-6 lg:grid-cols-2">
					<div className="flex col-span-1">
						<div className="mr-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
							<PhoneIcon className="h-6 w-6 text-white" aria-hidden="true"/>
						</div>
						<div>
							<h4 className="text-lg font-bold">Input Call</h4>
							<InputParagraph />
							<audio className="mt-8" ref={inputCallAudioRef} src={'audio/SNR_20dB/input.wav'} controls />
						</div>
					</div>
				</div>


				<div className="relative my-12">
					<div className="absolute inset-0 flex items-center" aria-hidden="true">
						<div className="w-full border-t border-gray-300" />
					</div>
					<div className="relative flex justify-center">
						<span className="px-2 bg-white text-sm text-gray-500">Separated Audio</span>
					</div>
				</div>

				<div className="flex w-full grid grid-cols-1 gap-6 lg:grid-cols-2">
					<div className="flex col-span-1">
						<div className="mr-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
							<UserIcon className="h-6 w-6 text-white" aria-hidden="true"/>
						</div>
						<div>
							<h4 className="text-lg font-bold">First Caller</h4>
							<p className="mt-1">
								Pisces outputs the first speaker in the recording first. Due to this, it's easy to recognize speakers and roles in downstream tasks. When the second speaker talks, this track is silent.
							</p>
							<audio className="mt-8" ref={agentCallAudioRef} src={'audio/SNR_20dB/agent.wav'} controls />
						</div>
					</div>
					<div className="flex col-span-1">
						<div className="mr-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
							<UserIcon className="h-6 w-6 text-white" aria-hidden="true"/>
						</div>
						<div>
							<h4 className="text-lg font-bold">Second Caller</h4>
							<p className="mt-1">
								Pisces outputs the second speaker in the recording second. Pisces is trained to be robust to a number of unseen test cases, and it can reliably separate speakers even in hostile SNR conditions.
							</p>
							<audio className="mt-8" ref={callerAudioRef} src={'audio/SNR_20dB/caller.wav'} controls />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
  