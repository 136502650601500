// import { Fragment, useEffect } from 'react'
// import { Popover, Transition } from '@headlessui/react'
import { MailIcon } from '@heroicons/react/outline'

import useScript from "../hooks/useScript"
import "../SCSS/Components/AnimatedBackground.scss"

export default function Home() {
    useScript('/scripts/animated-background.js')

    return (
        <div className="min-h-screen bg-white">
            <main className="overflow-hidden">
            {/* Header */}
            <div className="bg-warm-gray-50">
                <div className="py-24 lg:py-32">
                    <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                        <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
                            Get in touch
                        </h1>
                        <p className="mt-6 text-xl text-warm-gray-500 max-w-3xl">
                            We're working on a website to showcase our work. In the meantime, please drop us a message via the contact form below!
                        </p>
                    </div>
                </div>
            </div>

            {/* Contact section */}
            <section className="relative bg-white" aria-labelledby="contact-heading">
                <div className="absolute w-full h-1/2 bg-warm-gray-50" aria-hidden="true" />
                {/* Decorative dot pattern */}
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <svg
                        className="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-warm-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                    </svg>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="relative bg-white shadow-xl">
                        <h2 id="contact-heading" className="sr-only">
                            Contact us
                        </h2>

                        <div className="grid grid-cols-1 lg:grid-cols-3 mb-12">
                            {/* Contact information */}
                            <div className="relative overflow-hidden bg-gradient-to-b from-teal-500 to-teal-600">
                                <div className="relative my-10 mx-6 sm:mx-10 xl:m-12 z-50">
                                    <h3 className="text-4xl font-medium text-black">Contact information</h3>
                                    <dl className="mt-8 space-y-6">
                                        <dt>
                                            <span className="sr-only">Email</span>
                                        </dt>
                                        <dd className="flex text-base text-black">
                                            <MailIcon className="flex-shrink-0 w-6 h-6" aria-hidden="true" />
                                            <span className="ml-3">pat@conreylabs.io</span>
                                        </dd>
                                    </dl>
                                </div>
                                <canvas id="canvas" className="animated-background-container" width="32px" height="32px" />
                            </div>

                            {/* Contact form */}
                            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                                <h3 className="text-lg font-medium text-warm-gray-900">Send us a message</h3>
                                <form name="main-contact" data-netlify="true" method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                    <div>
                                        <label htmlFor="first-name" className="block text-sm font-medium text-warm-gray-900">
                                            First name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="last-name" className="block text-sm font-medium text-warm-gray-900">
                                            Last name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autoComplete="family-name"
                                            className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-warm-gray-900">
                                            Email
                                        </label>
                                        <div className="mt-1">
                                            <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex justify-between">
                                            <label htmlFor="phone" className="block text-sm font-medium text-warm-gray-900">
                                            Phone
                                            </label>
                                            <span id="phone-optional" className="text-sm text-warm-gray-500">
                                            Optional
                                            </span>
                                        </div>
                                        <div className="mt-1">
                                            <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            autoComplete="tel"
                                            className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                                            aria-describedby="phone-optional"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="subject" className="block text-sm font-medium text-warm-gray-900">
                                            Subject
                                        </label>
                                        <div className="mt-1">
                                            <input
                                            type="text"
                                            name="subject"
                                            id="subject"
                                            className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border-warm-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <div className="flex justify-between">
                                            <label htmlFor="message" className="block text-sm font-medium text-warm-gray-900">
                                                Message
                                            </label>
                                            <span id="message-max" className="text-sm text-warm-gray-500">
                                                Max. 500 characters
                                            </span>
                                        </div>
                                        <div className="mt-1">
                                            <textarea
                                                id="message"
                                                name="message"
                                                rows={4}
                                                className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-gray-500 focus:border-gray-500 border border-warm-gray-300 rounded-md"
                                                aria-describedby="message-max"
                                                defaultValue={''}
                                            />
                                        </div>
                                    </div>
                                    <div data-netlify-recaptcha="true"></div>
                                    <div className="sm:col-span-2 sm:flex sm:justify-end">
                                        <button
                                            type="submit"
                                            className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-black hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:w-auto"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
    )
}
