import {
    Routes,
    Route,
    BrowserRouter
} from "react-router-dom";

// import SiteNavigationBar from "./Components/SiteNavigationBar"
import Footer from "./Components/Footer";

import Home from "./Pages/Home"
import Pisces from "./Pages/Pisces"

function App() {
    return (
        <div> 
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/pisces" element={<Pisces/>} />
                </Routes>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
