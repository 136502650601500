import useScript from "../hooks/useScript"

export default function Footer() {
	useScript('/scripts/iubenda.js');

	return (
		<footer className="bg-white">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
				<div className="mt-8 md:mt-0 md:order-1">
					<a href="https://www.iubenda.com/privacy-policy/15235676" className="mb-2 iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
					<p className="text-center text-base text-gray-400">&copy; 2022 ConreyLabs LLC. All rights reserved.</p>
				</div>
			</div>
		</footer>
	)
}
